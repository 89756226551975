<template>
  <div class="huntarea-module">
    <RouterView v-if="huntArea !== null" />
  </div>
</template>

<script>
/* global google */
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      boundary: 'map/boundary/boundary',
      subAreas: 'map/subAreas/getSubAreas',
      forbiddenAreas: 'map/subAreas/getForbiddenAreas',
      markers: 'map/markers/markers',
      lines: 'map/lines/lines',
      isAdmin: 'huntarea/members/isAdmin'
    })
  },

  beforeMount () {
    this.read(this.$route.params.huntAreaId)
  },

  beforeRouteUpdate (to, from, next) {
    // if user change area, we need to re-initiate everything
    if (
      to.params.huntAreaId &&
      from.params.huntAreaId &&
      (Number(to.params.huntAreaId) !== Number(from.params.huntAreaId))
    ) {
      this.read(to.params.huntAreaId)
    }

    next()
  },

  methods: {
    ...mapActions({
      readHuntArea: 'huntarea/read',
      readMembers: 'huntarea/members/read',
      readInvites: 'huntarea/members/invites/read',
      readGallery: 'huntarea/images/read',
      readBoundary: 'map/boundary/read',
      readMarkers: 'map/markers/read',
      readSubAreas: 'map/subAreas/readSubAreas',
      readForbiddenAreas: 'map/subAreas/readForbiddenAreas',
      readLines: 'map/lines/read',
      readTrails: 'map/trails/read'
    }),

    async read (huntAreaId) {
      try {
        this.readHuntArea(huntAreaId)
        this.readGallery(huntAreaId)
        this.readMap(huntAreaId)

        this.readMembers(huntAreaId).then(() => {
          if (this.isAdmin) {
            this.readInvites({ huntAreaId })
          }
        })

        window.localStorage.setItem('lastVisitedArea', huntAreaId)
      } catch (error) {
        // Don't display any error for now
        console.error(error)
      }
    },

    /*
      * We're prefetching the entire huntArea and its associated layers
      * As soon as the user lands on `/area/<huntAreaId>`
    */
    async readMap (huntAreaId) {
      await this.readBoundary(huntAreaId)

      const promises = [
        this.readMarkers(huntAreaId),
        this.readSubAreas(huntAreaId),
        this.readForbiddenAreas(huntAreaId),
        this.readLines(huntAreaId)
      ]

      this.readTrails(huntAreaId)

      await Promise.all(promises)

      if (!this.hasBoundary()) {
        this.centerOnMapObjects()
      }
    },

    hasBoundary () {
      return this.boundary && !this.boundary.empty()
    },

    centerOnMapObjects () {
      const hasObjectsInMap = this.subAreas.length > 0 || this.markers.length > 0 || this.forbiddenAreas.length > 0 || this.lines.length > 0

      if (hasObjectsInMap) {
        // Build a bounding box of all objects in the map except trails, and center on them
        const bounds = new google.maps.LatLngBounds()

        this.subAreas.forEach(subArea => subArea.borderCoordinates.forEach(coordinate => this.extendBounds(bounds, coordinate)))
        this.forbiddenAreas.forEach(forbiddenArea => forbiddenArea.borderCoordinates.forEach(coordinate => this.extendBounds(bounds, coordinate)))
        this.markers.forEach(marker => this.extendBounds(bounds, marker.location))
        this.lines.forEach(line => line.path.forEach(coordinate => this.extendBounds(bounds, coordinate)))

        this.$store.commit('map/setBounds', bounds)
      } else {
        this.centerOnUserPosition()
      }
    },

    extendBounds (bounds, latLng) {
      const { lat, lng } = latLng
      return bounds.extend(new google.maps.LatLng(lat, lng))
    },

    async centerOnUserPosition () {
      const currentPosition = this.$store.getters['user/getCurrentPosition']

      if (currentPosition !== null) {
        await this.$store.dispatch('map/setCenter', currentPosition)
        this.$store.dispatch('map/settings/setZoom', 12)
      }
    }
  }
}
</script>

<style lang="scss">
// Must be full height in order for the map to work.
// But should preferably be moved higher up the chain.
.huntarea-module {
  height: 100%;
}
</style>
